/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center" style={{"paddingBottom":27,"paddingTop":79}} name={"kontaktyamapa"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Kontakty a mapa"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":113,"paddingBottom":120}} name={"kontaktypodrobne"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/965c3e40d27d46d385ed085e1b11d2fa.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Title className="title-box ff--1 fs--43" style={{"marginTop":80}} content={"tel: 602 625 691"}>
              </Title>

              <Text className="text-box fs--20" content={"<span style=\"color: var(--color-custom-1);\">Na tomto telefonním čísle se spojíte přímo s osobou, která Vám ochotně poskytne veškeré informace o cenách, možnostech pronájmu i prohlídkách zámku.</span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5 btn-box--center" style={{"backgroundColor":"rgba(87,59,143,1)","marginTop":27}} content={"Volat uvedené číslo"} use={"tel"} href={"tel:+420602625691"} url={"tel:+420602625691"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/6b72aab19c4f4d1ca38a5ca397b2ec1c.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Title className="title-box ff--1 fs--43 w--400" style={{"marginTop":81}} content={"vidzin@volny.cz"}>
              </Title>

              <Text className="text-box fs--20" content={"<span style=\"color: var(--color-custom-1);\">Pokud nemůžete telefonovat, napište nám na výše uvedený email.\nPokud ale spěcháte, nebo se chcete jen na něco zeptat, doporučujeme raději zavolat.</span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5" style={{"backgroundColor":"rgba(87,59,143,1)","marginTop":62}} content={"Napsat email"} use={"mailto"} href={"mailto:vidzin@volny.cz?subject=vidzin.cz"} url={"mailto:vidzin@volny.cz?subject=vidzin.cz"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Kontakt/bd9f8784d70b4b548d89a0b10febe8e8.svg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":80}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Title className="title-box ff--1 fs--43 lh--14" style={{"marginTop":30}} content={"<a href=\"https://mapy.cz/zakladni?source=firm&amp;id=13433436&amp;x=12.9808174&amp;y=49.9661515&amp;z=18\" target=\"_blank\">Vidžín 8<br>330 40 Úterý</a><br>"}>
              </Title>

              <Text className="text-box fs--20" style={{"marginTop":35}} content={"<span style=\"color: var(--color-custom-1);\">Chcete-li si zobrazit polohu zámku na Mapy.cz, klikněte na adresu výše. Pro náhled naší vlastní mapky areálu využijte tlačítko níže.</span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr4 btn-box--pbtn5 btn-box--shape5 btn-box--shadow5" style={{"backgroundColor":"rgba(87,59,143,1)","marginTop":60}} content={"Přejít na mapu"} use={"page"} href={"/kontakt#mapa"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingBottom":71,"paddingTop":60}} layout={"l9"} name={"mapa"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":1776}} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image className="--center" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/7361/2d2bf898e85741c4aeb43bc3cc65482e_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":1243}} srcSet={"https://cdn.swbpg.com/t/7361/2d2bf898e85741c4aeb43bc3cc65482e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/7361/2d2bf898e85741c4aeb43bc3cc65482e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/7361/2d2bf898e85741c4aeb43bc3cc65482e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/7361/2d2bf898e85741c4aeb43bc3cc65482e_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--20 pl--0" style={{"maxWidth":562}} content={"<span style=\"color: var(--color-custom-1);\">Máte raději navigaci?<br><span style=\"font-weight: bold;\">Nastavte si GPS</span>:&nbsp;49.9658019N, 12.9796603E</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"backgroundColor":"#dbd6f3","paddingTop":51,"paddingBottom":46}} layout={"l1"} name={"paticka"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36" style={{"marginTop":0}} content={"Odkazy"}>
              </Title>

              <Text className="text-box text-box--center fs--22" style={{"maxWidth":334,"marginTop":30}} content={"<a href=\"/nf-vidzin#nfvidzinuvod\" style=\"color: var(--color-custom-1);\">Nadační fond Vidžín</a>"}>
              </Text>

              <Text className="text-box text-box--center fs--22" style={{"marginTop":19}} content={"<a href=\"/#prohlidky\" style=\"color: var(--color-custom-1);\">Prohlídky zámku</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36" content={"Zámek Vidžín"}>
              </Title>

              <Text className="text-box text-box--center fs--22" style={{"maxWidth":311,"marginTop":21}} content={"<span style=\"color: var(--color-custom-1);\">Nadační fond Vidžín\n<br>Vidžín č.8, 330 40 Úterý<br><span style=\"font-weight: bold;\">tel:&nbsp;602 625 691</span></span>"}>
              </Text>

              <Image className="--center" style={{"maxWidth":196,"marginTop":27,"paddingLeft":33}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=350x_.png 350w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=660x_.png 660w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=860x_.png 860w, https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=1400x_.png 1400w"} alt={"logo zámek Vidžín"} src={"https://cdn.swbpg.com/t/7361/5be5ec205546413a9aed44ed5cf36cf4_s=860x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--36" style={{"marginTop":0}} content={"Sociální sítě"}>
              </Title>

              <Image style={{"maxWidth":40,"paddingLeft":0,"marginTop":31}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/cbdf2b77885c4038bc96800ca6699c5d_s=350x_.png 350w"} alt={"facebook"} src={"https://cdn.swbpg.com/t/7361/cbdf2b77885c4038bc96800ca6699c5d_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.facebook.com/zameckafaravidzin"} url={"https://www.facebook.com/zameckafaravidzin"}>
              </Image>

              <Image style={{"maxWidth":38,"paddingLeft":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/7f7817b7d3754a5a8b1235689963ad5d_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/7361/7f7817b7d3754a5a8b1235689963ad5d_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.instagram.com/zamekvidzin/"} url={"https://www.instagram.com/zamekvidzin/"}>
              </Image>

              <Image style={{"maxWidth":42}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/7361/213f4c40ad994af0b7ad683c6f8158d8_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/7361/213f4c40ad994af0b7ad683c6f8158d8_s=350x_.png"} lightbox={false} use={"url"} href={"https://www.youtube.com/watch?v=aIwoNcyrEC4"} url={"https://www.youtube.com/watch?v=aIwoNcyrEC4"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}